module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-8FE0GRXHPY"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":true,"respectDNT":true}},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
