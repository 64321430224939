/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// Bootstrap
import './static/styles/plugins/bootstrap.min.css'

// Root Styles
import './static/styles/app.css'
import './static/styles/typography.css'
import './static/styles/bootstrap.overrides.css'

// 3rd-party plugin Styles
import './static/styles/plugins/slick.min.css'
import './static/styles/plugins/slick-theme.min.css'
